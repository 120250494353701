import React from 'react';
/*
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider, ErrorProvider } from './providers';
import './App.css';
*/
import Cookies from 'js-cookie';
/*
import DefaultLayout from './layouts/DefaultLayout';
import NewLayout from './layouts/NewLayout';
import OriginalRouter from './pages/PageRouter';
import NewRouter from './new_pages/PageRouter';
import Donation from './pages/Donation';
import DonationThankYou from './pages/DonationThankYou';
*/
const LegacyApp = React.lazy(() => import('./LegacyApp'));
const V2App = React.lazy(() => import('./v2App'));


function App() {
  if (Cookies.get('layout') === 'v2') {
    return (
      <React.Suspense fallback={<>...</>}>
        <V2App />
      </React.Suspense>

    )
  }

  return (
    <React.Suspense fallback={<>...</>}>
      <LegacyApp />
    </React.Suspense>
  )
}

export default App
